import React, { PureComponent } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export class Transition extends PureComponent<any> {
    render() {
        const { children, location } = this.props;

        return (
            <AnimatePresence exitBeforeEnter initial={false}>
                <motion.div
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={{
                        initial: { opacity: 0 },
                        animate: {
                            opacity: 1,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                        },
                        exit: { opacity: 0 },
                    }}
                    key={location.pathname}
                >
                    {children}
                </motion.div>
            </AnimatePresence>
        );
    }
}
