import wrapPageElementWithTransition from 'helpers/wrapPageElement';
import React from 'react';
import AppProvider from 'store/provider';
import { theme } from './src/components/theme';
import { ThemeProvider } from './src/components/ThemeProvider';
const Sentry = require('@sentry/browser');

if (
    typeof window !== 'undefined' &&
    window.location.href.indexOf('community.audent.io') !== -1
) {
    Sentry.init({
        dsn: 'https://a1e8d3ad382b40c19e4fdea8a88e0830@sentry.audent.io/31',
    });
}

// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
    return (
        <AppProvider>
            <ThemeProvider theme={theme}>{element}</ThemeProvider>
        </AppProvider>
    );
};

// Page Transitions
export const wrapPageElement = wrapPageElementWithTransition;
