const typography = {
    letterSpacings: {
        tighter: '-0.05em',
        tight: '-0.025em',
        normal: '0',
        wide: '0.025em',
        wider: '0.05em',
        widest: '0.1em',
    },
    lineHeights: {
        normal: 'normal',
        none: '1',
        shorter: '1.25',
        short: '1.375',
        base: '1.5',
        tall: '1.625',
        taller: '2',
    },
    fontWeights: {
        hairline: 100,
        thin: 200,
        light: 300,
        normal: 400,
        medium: 500,
        semibold: 500,
        bold: 700,
        extrabold: 800,
        black: 900,
    },
    fonts: {
        heading: `Larsseit, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
        body: `Larsseit, -apple-system, BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
        mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
    },
    fontSizes: {
        // https://projects.invisionapp.com/d/#/console/19706679/412741267/preview
        smallSubtitle: '1.1rem',
        smallBody: '1.4rem',
        body: '1.6rem',
        paragraphTitle: '2rem',
        subtitle: '2.4rem',
        subsectionHeading: '3rem',
        sectionHeading: '3.6rem',
        heroHeading: '4.8rem',
    },
};

export default typography;
